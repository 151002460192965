import PoppinsWOFF from "../assets/fonts/Poppins-Regular.woff";
import PoppinsBoldWOFF from "../assets/fonts/Poppins-Bold.woff";
import PoppinsExtraBoldWOFF from "../assets/fonts/Poppins-ExtraBold.woff";
import PoppinLightWOFF from "../assets/fonts/Poppins-Light.woff";
import PoppinsMediumWOFF from "../assets/fonts/Poppins-Medium.woff";

const poppinsMedium = {
  fontFamily: "Poppins-Medium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Poppins'),
		local('Poppins-Medium'),
		url(${PoppinsMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const poppinsBold = {
  fontFamily: "Poppins-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Poppins'),
		local('Poppins-Bold'),
		url(${PoppinsBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const poppinsExtraBold = {
  fontFamily: "Poppins-ExtraBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Poppins'),
		local('Poppins-ExtraBold'),
		url(${PoppinsExtraBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const poppinsLight = {
  fontFamily: "Poppins-Light",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Poppins'),
		local('Poppins-Light'),
		url(${PoppinLightWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const poppins = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Poppins'),
		local('Poppins-Regular'),
		url(${PoppinsWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [poppins, poppinsMedium, poppinsBold, poppinsExtraBold, poppinsLight];

export default fonts;
