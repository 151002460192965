import { ethers } from "ethers";
import { addresses } from "../constants";
import { setAll } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { abi as TokenIDOAbi } from "../abis/TokenIDO.json";
import { IBaseAsyncThunk } from "./interfaces";

const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const TokenIDOContract = new ethers.Contract(addresses[networkID].TokenIDO, TokenIDOAbi, provider);
    const endOfSale = await TokenIDOContract.IDOWhiteListEndTimestamp();
    const startTime = await TokenIDOContract.IDOStartTimestamp();

    const fundraiseGoal = await TokenIDOContract.MaxIDOPayInAmount();

    const allocation = await TokenIDOContract.receivedIDOPayInAmount();

    const price = await TokenIDOContract.whiteListPrice();
    const edePrice = 1 / Number(ethers.utils.formatUnits(price, 4));

    return {
      endOfSale,
      startTime,
      fundraiseGoal: ethers.utils.formatEther(fundraiseGoal),
      allocation: ethers.utils.formatEther(allocation),
      price: edePrice,
    };
  },
);

interface Leaderboard {
  readonly addr: string;
  readonly userClaimed: string;
}
interface IAppData {
  readonly GangsterMinted: number;
  readonly LEADMinted: number;
  readonly SWATMinted: number;

  readonly totalGangsterStaked: number;
  readonly totalLEADStaked: number;
  readonly totalSWATStaked: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
