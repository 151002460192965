import { ethers } from "ethers";
import { addresses } from "../constants";
import { setAll } from "../helpers";
import { abi as TokenIDOAbi } from "../abis/TokenIDO.json";
import { abi as IERC20Abi } from "../abis/IERC20.json";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const BUSDContract = new ethers.Contract(addresses[networkID].BUSD, IERC20Abi, provider);
    const balance_busd = await BUSDContract.balanceOf(address);

    const alphaEDEContract = new ethers.Contract(addresses[networkID].AlphaEDE, IERC20Abi, provider);
    const balance_alphaEDE = await alphaEDEContract.balanceOf(address);

    const TokenIDOContract = new ethers.Contract(addresses[networkID].TokenIDO, TokenIDOAbi, provider);
    const isWhiteListed = await TokenIDOContract.whiteListed(address);
    const maxWhitelistSinglePayInAmount = isWhiteListed
      ? await TokenIDOContract.MaxWhitelistSinglePayInAmount()
      : await TokenIDOContract.MaxCommonSinglePayInAmount();

    return {
      isWhiteListed,
      balance_busd: ethers.utils.formatEther(balance_busd),
      balance_alphaEDE: ethers.utils.formatEther(balance_alphaEDE),
      maxWhitelistSinglePayInAmount: ethers.utils.formatEther(maxWhitelistSinglePayInAmount),
    };
  },
);

interface IAccountSlice {
  loading: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
