import { Box, Button, Link, SvgIcon, Dialog } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./header.scss";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/logoM.svg";
import Close from "../../assets/images/close.svg";
import { ReactComponent as Wallet } from "../../assets/images/wallet.svg";
import { useWeb3Context } from "src/hooks";
import { shorten } from "../../helpers";
import { info, success } from "src/slices/MessagesSlice";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 680px)");

  const dispatch = useDispatch();
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  const location = useLocation();
  const [showInfo, setShowInfo] = useState(false);
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const { connect, disconnect, connected, web3, address, chainID, switchNetwork2 } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  let buttonText = "Connect Wallet";
  let clickFunc = () => {
    if (isConnected) {
      setShowInfo(true);
    } else {
      connect();
    }
  };

  if (isConnected) {
    buttonText = shorten(address); // "Disconnect";
    // clickFunc = disconnect;
  }
  const handleClose2 = () => {
    setShowInfo(false);
  };

  const handlerLogout = disconnect;

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <Box display="flex" justifyContent="center" className="header-view">
      <div className="container">
        <Box display="flex" alignItems="center" justifyContent="space-between" className="header">
          <a href="https://ede.finance/" target="_blank">
            <img src={isSmallScreen ? LogoM : Logo} alt="logo" className="logo" />
          </a>
          <div className="connectBtnBox" onClick={clickFunc}>
            <Button variant="contained" className="connectBtn ml-12">
              <SvgIcon className="downIcon" component={Wallet} viewBox="0 0 13 12" />
              <span className="ml-12">{buttonText}</span>
            </Button>
          </div>
        </Box>
      </div>
      <Dialog open={showInfo} onClose={handleClose2}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
              <div className="">
                <div className="font-18 font-weight-b">My Wallet</div>
              </div>
              <img src={Close} onClick={handleClose2} />
            </Box>
            <div className="networkList font-16">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`networkItem networkItemActive }`}
              >
                <Box display="flex" alignItems="center" className="networkItemLeft">
                  <div className="ml-12">{shorten(address)}</div>
                </Box>
                <Box display="flex" alignItems="center" className="isConnected">
                  <div className="font-14">Connected</div>
                  <div className="connected"></div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems="center"
                className="mt-24"
              >
                <div
                  className="connectBtnBox"
                  onClick={() => {
                    copy(address), dispatch(success("copy success"));
                  }}
                >
                  <Button variant="contained" className="connectBtn">
                    <span className="">Copy Address</span>
                  </Button>
                </div>
                <div
                  className={`connectBtnBox ${isSmallScreen && "mt-12"}`}
                  onClick={() => {
                    window.open(`https://testnet.bscscan.com/address/${address}`);
                  }}
                >
                  <Button variant="contained" className="connectBtn">
                    <span className="">View in Explorer</span>
                  </Button>
                </div>
                <div className={`connectBtnBox ${isSmallScreen && "mt-12"}`} onClick={handlerLogout}>
                  <Button variant="contained" className="stakeBtn">
                    Disconnect
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
    </Box>
  );
}
