interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  97: {
    TokenIDO: "0x898C332cc26cB97Ec88517A538836264fCa4FBEE",
    BUSD: "0x83aD7A3C55f40a00B53726dC114443d245d9ec48",
    AlphaEDE: "0x206Bf5744cB5BD1B69647C4bF40438403680d34C",
  },
  56: {
    TokenIDO: "0xBB2f4a40FBA46F15C38df0B10a53eeE14331fE43",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    AlphaEDE: "0x43F649919f4ac48874D7f65D361702E4447Dec0c",
  },
};
